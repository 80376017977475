
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
// 通用组件
import Layout from "./components/Layout"
import Header from "./components/Header/index0.jsx"
import { signInWithGoogle } from './components/Auth/SignIn';
// Pages
import Settings from './pages/Settings';
import Conversation from './pages/Conversation';
import SharedConversation from './pages/SharedConversation';
import Home from './pages/Home';
import CharCreate from './pages/CharCreate';
import CharDelete from './pages/CharDelete';
import Privacy from './pages/Privacy';
// Custom hooks
import useWebsocket from './hooks/useWebsocket';
import useMediaRecorder from './hooks/useMediaRecorder';
import useSpeechRecognition from './hooks/useSpeechRecognition';
import useWebRTC from './hooks/useWebRTC';
import useHark from './hooks/useVAD';
// 死循环
import MainStudy from './education/Subject/MainStudy';

import AskAnythingNB from './education/askAnythingComponent/AskAnythingNB';
import MyComponent from './test';
import Default from './pages/default0';
import Default1 from './pages/default';
import Login from './pages/Login';
import Testaliyun from './testaliyun';
import UploadFile from './uploadfile';
import UserSetting from './education/UserSetting';
import { createTheme } from '@mui/material';
import MainStudyFeynman from './education/SubjectFeynman/MainStudyFeynman';
import Testlatex from './Testlatex';
import MyOrder from './MyOrder';
import FamilyHead from './education/familyhead/FamilyHead';
import TestGeo from './testgeo';
import AdminManage from './admin/Admin';
import Student from './education/student/Student';
import ProxyManage from './proxy/ProxyManage';
import PbMain from './education/PictureBookMain/PbMain';
import Support from './pages/Support.jsx';
import ScienceC from './education/ScienceC/ScienceC.jsx';
import Algorithm from './education/Algorithm.jsx';
import BigCleverness from './education/BigCleverness/BigCleverness.jsx';
import './fonts.css';
import InteractiveCourseware from './education/InteractiveCourseware/InteractiveCourseware.jsx';

// 状态保留
import { getUserInfo } from './store/model/user'
import AlgorithmBook from './education/AlgorithmBook.jsx';
import MyOiModule from './education/myoi/MyOiModule.jsx';
import ProblemTeacher from './education/myoi/ProblemTeacher.jsx';
import StudyDocumentComponent from './education/StudyDocument/StudyDocumentComponent.jsx';
import OiCoursewareComponent from './education/OiCourseware/OiCoursewareComponent.jsx';
const App = () => {
  // 获取基本信息 
  getUserInfo()
  
  const [sessionId, setSessionId] = useState('');
  const [preferredLanguage, setPreferredLanguage] = useState('English');
  const [selectedDevice, setSelectedDevice] = useState('');
  const [selectedModel, setSelectedModel] = useState('gpt-3.5-turbo-16k');

  const [useSearch, setUseSearch] = useState(false);
  const [useQuivr, setUseQuivr] = useState(false);
  const [quivrApiKey, setQuivrApiKey] = useState('');
  const [quivrBrainId, setQuivrBrainId] = useState('');
  const [useMultiOn, setUseMultiOn] = useState(false);
  const [useEchoCancellation, setUseEchoCancellation] = useState(false);
  const [user, setUser] = useState(null);
  const isLoggedIn = useRef(true);
  const [token, setToken] = useState('');
  const [isThinking, setIsThinking] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isResponding, setIsResponding] = useState(false);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [messageInput, setMessageInput] = useState('');
  const [isCallView, setIsCallView] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [isTextStreaming, setIsTextStreaming] = useState(false);
  const [characterGroups, setCharacterGroups] = useState([]);
  const [characterConfirmed, setCharacterConfirmed] = useState(false);
  const [messageId, setMessageId] = useState('');
  const audioPlayer = useRef(null);
  const callActive = useRef(false);
  const harkInitialized = useRef(false);
  const audioSent = useRef(false);
  const shouldPlayAudio = useRef(false);
  const audioQueue = useRef([]);
  const isConnecting = useRef(false);
  const isConnected = useRef(false);
  const isMobile = window.innerWidth <= 768;

  const [selectedGrade, setSelectedGrade] = useState('7');
  const [selectedDefaultAudioSpeed, setSelectedDefaultAudioSpeed] = useState('1');
  const RefButtonRefUpdateList = useRef(null);
  const [oneMessageAll, setOneMessageAll] = useState('');
  const [oneMessageAllFromUserAudioToText, setOneMessageAllFromUserAudioToText] = useState('');
  const [chatId, setChatId] = useState('');

  const [isLoggedInS, setIsLoggedInS] = useState(false);

  const [username, setUsername] = useState('');

  useEffect(() => {

    const curToken = localStorage.getItem('access_token');
    if (curToken) {
      setToken(curToken);
      isLoggedIn.current = true;
    }
    else {
      isLoggedIn.current = false;
    }
  }, []);
  isLoggedIn.current = true
  useEffect(() => {
  }, [isLoggedIn]);

  const stopAudioPlayback = () => {
    if (audioPlayer.current) {
      audioPlayer.current.pause();
      shouldPlayAudio.current = false;

    }
    audioQueue.current = [];
    setIsPlaying(false);
  };


  // Helper functions
  const handleSocketOnOpen = async event => {
    console.log('successfully connected');
    isConnected.current = true;
    await connectMicrophone(selectedDevice);
    if (!useEchoCancellation) {
      initializeSpeechRecognition();
    }
    await connectPeer(selectedDevice);
  };

  const handleSocketOnMessage = event => {

    if (typeof event.data === 'string') {
      const message = event.data;
      if (!isTextStreaming) setIsTextStreaming(true);
      if (message === '[end]\n' || message.match(/\[end-([a-zA-Z0-9]+)\]/)) { //greeting end
        setIsTextStreaming(false);
        setIsResponding(false);
        setTextAreaValue(prevState => prevState + '\n\n');


        const parts = message.match(/\[end-(.+)\]-(.+)-([^-]+)/);
        console.log("消息：" + parts);
        if (parts) {
          const mesid = parts[1];
          const chatdbid = parts[2];
          const messall = parts[3];
          console.log("消息conver:" + messall);
          setOneMessageAll(messall);//完整的一条ai回复消息，传递给conversation用于更新聊天消息列表
          setMessageId(mesid);
          setChatId(chatdbid);
          console.log("dbid ai:" + chatdbid);
        }

      } else if (message === '[thinking]\n') {
        setIsThinking(true);
      } else if (message.startsWith('[+]-')) {
        // [+] indicates the transcription is done. stop playing audio
        let msg = message.split('[+]You said: ');



        const parts = message.split('-');
        const part1 = parts[0];
        const dbchatid = parts[1];
        console.log("dbid:" + dbchatid);
        const allOneMess = parts.slice(2).join('-');
        setTextAreaValue(prevState => prevState + `\nYou> ${allOneMess}\n`);
        setChatId(dbchatid);
        setOneMessageAllFromUserAudioToText(allOneMess);//用户语音消息转录出来的文本传递给conversation，更新聊天列表
        stopAudioPlayback();
      } else if (
        message.startsWith('[=]' || message.match(/\[=([a-zA-Z0-9]+)\]/))
      ) {
        // [=] or [=id] indicates the response is done
        setTextAreaValue(prevState => prevState + '\n\n');
      } else {
        setIsThinking(false);
        setIsResponding(true);
        setTextAreaValue(prevState => prevState + `${event.data}`);

        // if user interrupts the previous response, should be able to play audios of new response
        shouldPlayAudio.current = true;
      }
    } else {
      // binary data
      shouldPlayAudio.current = true;
      console.log("语音：" + shouldPlayAudio.current);
      if (!shouldPlayAudio.current) {
        console.log('should not play audio');
        return;
      }
      audioQueue.current.push(event.data);
      if (audioQueue.current.length === 1) {
        setIsPlaying(true); // this will trigger playAudios in CallView.
      }
    }
  };


  const handleSocketOnMessage111 = event => {
    if (typeof event.data === 'string') {
      const message = event.data;
      if (!isTextStreaming) setIsTextStreaming(true);
      if (message === '[end]\n' || message.match(/\[end=([a-zA-Z0-9]+)\]/)) {
        setIsTextStreaming(false);
        setIsResponding(false);
        setTextAreaValue(prevState => prevState + '\n\n');
        const messageIdMatches = message.match(/\[end=([a-zA-Z0-9]+)\]/);
        if (messageIdMatches) {
          const messageId = messageIdMatches[1];
          setMessageId(messageId);
        }
      } else if (message === '[thinking]\n') {
        setIsThinking(true);
      } else if (message.startsWith('[+]You said: ')) {
        // [+] indicates the transcription is done. stop playing audio
        let msg = message.split('[+]You said: ');
        setTextAreaValue(prevState => prevState + `\nYou> ${msg[1]}\n`);
        stopAudioPlayback();
      } else if (
        message.startsWith('[=]' || message.match(/\[=([a-zA-Z0-9]+)\]/))
      ) {
        // [=] or [=id] indicates the response is done
        setTextAreaValue(prevState => prevState + '\n\n');
      } else {
        setIsThinking(false);
        setIsResponding(true);
        setTextAreaValue(prevState => prevState + `${event.data}`);

        // if user interrupts the previous response, should be able to play audios of new response
        shouldPlayAudio.current = true;
      }
    } else {
      // binary data
      if (!shouldPlayAudio.current) {
        console.log('should not play audio');
        return;
      }
      audioQueue.current.push(event.data);
      if (audioQueue.current.length === 1) {
        setIsPlaying(true); // this will trigger playAudios in CallView.
      }
    }
  };


  const handleOnTrack = event => {
    if (event.streams && event.streams[0]) {
      audioPlayer.current.srcObject = event.streams[0];
    }
  };

  // Use custom hooks
  const { socketRef, send, connectSocket, closeSocket } = useWebsocket(
    token,
    handleSocketOnOpen,
    handleSocketOnMessage,
    selectedModel,
    preferredLanguage,
    useSearch,
    useQuivr,
    useMultiOn,
    selectedCharacter,
    setSessionId,
    selectedDefaultAudioSpeed,
    selectedGrade
  );
  const {
    isRecording,
    setIsRecording,
    connectMicrophone,
    startRecording,
    stopRecording,
    closeMediaRecorder,
  } = useMediaRecorder(isConnected, audioSent, callActive, send, closeSocket);
  const {
    startListening,
    stopListening,
    closeRecognition,
    initializeSpeechRecognition,
  } = useSpeechRecognition(
    callActive,
    preferredLanguage,
    shouldPlayAudio,
    isConnected,
    audioSent,
    stopAudioPlayback,
    send,
    startRecording,
    stopRecording,
    setTextAreaValue
  );
  const {
    pcRef,
    otherPCRef,
    micStreamRef,
    audioContextRef,
    incomingStreamDestinationRef,
    connectPeer,
    closePeer,
  } = useWebRTC(handleOnTrack);
  const { speechEventsCallback, enableHark, disableHark } = useHark();
  const connectSocketWithState = useCallback(() => {
    isConnecting.current = true;
    connectSocket();
    isConnecting.current = false;
  }, [isConnecting, connectSocket]);

  const closeSocketWithState = () => {
    closeSocket();
  };

  // Handle Button Clicks
  const connect = async () => {
    try {
      // requires login if user wants to use gpt4 or claude.
      if (selectedModel !== 'gpt-3.5-turbo-16k') {
        if (isLoggedIn.current) {
          connectSocketWithState();
        } else {
          signInWithGoogle(isLoggedIn, setToken).then(() => {
            if (isLoggedIn.current) {
              connectSocketWithState();
            }
          });
        }
      } else {
        connectSocketWithState();
      }
    } catch (error) {
      console.error('Error during sign in or connect:', error);
    }
  };

  const handleStopCall = () => {
    if (useEchoCancellation) {
      setIsRecording(false);
      disableHark();
    } else {
      stopRecording();
      stopListening();
    }
    stopAudioPlayback();
    callActive.current = false;
  };

  const handleContinueCall = () => {
    if (useEchoCancellation) {
      if (!harkInitialized.current) {
        speechEventsCallback(
          micStreamRef.current,
          () => {
            stopAudioPlayback();
            startRecording();
          },
          () => {
            // Stops recording and send interim audio clip to server.
            send('[&Speech]');
            stopRecording();
          },
          () => {
            send('[SpeechFinished]');
          }
        );
        harkInitialized.current = true;
      }
      setIsRecording(true);
      enableHark();
    } else {
      setIsRecording(true);
      startRecording();
      startListening();
    }
    shouldPlayAudio.current = true;
    callActive.current = true;
  };

  const handleDisconnect = () => {
    if (socketRef && socketRef.current) {
      // stop media recorder, speech recognition and audio playing
      stopAudioPlayback();
      closeMediaRecorder();
      if (!useEchoCancellation) {
        closeRecognition();
      }
      closePeer();
      callActive.current = false;
      shouldPlayAudio.current = false;
      audioSent.current = false;
      harkInitialized.current = false;

      // reset everything to initial states
      setSelectedCharacter(null);
      setCharacterConfirmed(false);
      setCharacterGroups([]);
      setIsCallView(false);
      setTextAreaValue('');
      setSelectedModel('gpt-3.5-turbo-16k');
      setPreferredLanguage('English');

      // close web socket connection
      closeSocketWithState();
      isConnected.current = false;
    }
  };

  return (
    <Router>
      <Layout>
        <Header
          user={user}
          username={username}
          isLoggedIn={isLoggedIn}
          isLoggedInS={isLoggedInS}
          setIsLoggedInS={setIsLoggedInS}
          setToken={setToken}
          handleDisconnect={handleDisconnect}
        />
        <Routes>
          <Route path='/' element={<Default />} />
          <Route
            path='/spoken'
            element={
              isLoggedIn.current ?
                <Home
                  isMobile={isMobile}
                  selectedCharacter={selectedCharacter}
                  setSelectedCharacter={setSelectedCharacter}
                  isPlaying={isPlaying}
                  characterGroups={characterGroups}
                  setCharacterGroups={setCharacterGroups}
                  setCharacterConfirmed={setCharacterConfirmed}
                  characterConfirmed={characterConfirmed}
                  token={token}
                  setToken={setToken}
                  isLoggedIn={isLoggedIn.current}
                />
                : <Navigate to="/login" />
            }
          />
          <Route
            path='/settings'
            element={
              isLoggedIn.current ?
                <Settings
                  setSelectedCharacter={setSelectedCharacter}
                  isMobile={isMobile}
                  preferredLanguage={preferredLanguage}
                  setPreferredLanguage={setPreferredLanguage}
                  selectedDevice={selectedDevice}
                  setSelectedDevice={setSelectedDevice}
                  selectedModel={selectedModel}
                  setSelectedModel={setSelectedModel}
                  isLoggedIn={isLoggedIn}
                  token={token}
                  setToken={setToken}
                  useSearch={useSearch}
                  setUseSearch={setUseSearch}
                  useQuivr={useQuivr}
                  setUseQuivr={setUseQuivr}
                  quivrApiKey={quivrApiKey}
                  setQuivrApiKey={setQuivrApiKey}
                  quivrBrainId={quivrBrainId}
                  setQuivrBrainId={setQuivrBrainId}
                  useMultiOn={useMultiOn}
                  setUseMultiOn={setUseMultiOn}
                  useEchoCancellation={useEchoCancellation}
                  setUseEchoCancellation={setUseEchoCancellation}
                  send={send}
                  connect={connect}
                  setIsCallView={setIsCallView}
                  shouldPlayAudio={shouldPlayAudio}
                  selectedGrade={selectedGrade}
                  setSelectedGrade={setSelectedGrade}
                  selectedDefaultAudioSpeed={selectedDefaultAudioSpeed}
                  setSelectedDefaultAudioSpeed={setSelectedDefaultAudioSpeed}
                />
                : <Navigate to="/login" />
            }
          />
          <Route
            path='/conversation'
            element={
              isLoggedIn.current ?
                <Conversation
                  isConnecting={isConnecting}
                  isConnected={isConnected}
                  isCallView={isCallView}
                  isRecording={isRecording}
                  isPlaying={isPlaying}
                  isThinking={isThinking}
                  isResponding={isResponding}
                  audioPlayer={audioPlayer}
                  handleStopCall={handleStopCall}
                  handleContinueCall={handleContinueCall}
                  audioQueue={audioQueue}
                  audioContextRef={audioContextRef}
                  audioSourceNodeRef={incomingStreamDestinationRef}
                  setIsPlaying={setIsPlaying}
                  handleDisconnect={handleDisconnect}
                  setIsCallView={setIsCallView}
                  send={send}
                  stopAudioPlayback={stopAudioPlayback}
                  textAreaValue={textAreaValue}
                  setTextAreaValue={setTextAreaValue}
                  messageInput={messageInput}
                  setMessageInput={setMessageInput}
                  useSearch={useSearch}
                  setUseSearch={setUseSearch}
                  setUseEchoCancellation={setUseEchoCancellation}
                  callActive={callActive}
                  startRecording={startRecording}
                  stopRecording={stopRecording}
                  preferredLanguage={preferredLanguage}
                  setPreferredLanguage={setPreferredLanguage}
                  selectedCharacter={selectedCharacter}
                  setSelectedCharacter={setSelectedCharacter}
                  setSelectedModel={setSelectedModel}
                  setSelectedDevice={setSelectedDevice}
                  setUseMultiOn={setUseMultiOn}
                  connect={connect}
                  messageId={messageId}
                  token={token}
                  isTextStreaming={isTextStreaming}
                  sessionId={sessionId}
                  oneMessageAll={oneMessageAll}//新增，ai回复的整条消息
                  oneMessageAllFromUserAudioToText={oneMessageAllFromUserAudioToText}
                  chatId={chatId}
                />
                : <Navigate to="/login" />
            }
          />
          <Route path='/shared' element={isLoggedIn.current ? <SharedConversation /> : <Navigate to="/login" />} />
          <Route path='/create' element={isLoggedIn.current ? <CharCreate token={token} /> : <Navigate to="/login" />} />
          <Route
            path='/delete'
            element={
              isLoggedIn.current ?
                <CharDelete
                  token={token}
                  isMobile={isMobile}
                  characterGroups={characterGroups}
                />
                : <Navigate to="/login" />
            }
          />
          <Route path='/default' element={<Default />} />
          <Route path='/askanything' element={isLoggedIn.current ? <AskAnythingNB /> : <Navigate to="/default" />} />
          <Route path='/mainstudy' element={isLoggedIn.current ? <MainStudy /> : <Navigate to="/default" />} />
          <Route path='/privacy' element={isLoggedIn.current ? <Privacy /> : <Navigate to="/default" />} />
          <Route path='/support' element={isLoggedIn.current ? <Support /> : <Navigate to="/default" />} />
          <Route path='/mainstudyfeynman' element={isLoggedIn.current ? <MainStudyFeynman /> : <Navigate to="/default" />} />
          <Route path='/pbmain' element={isLoggedIn.current ? <PbMain /> : <Navigate to="/default" />} />
          <Route path='/test' element={isLoggedIn.current ? <MyComponent /> : <Navigate to="/default" />} />
          <Route path='/testaliyun' element={isLoggedIn.current ? <Testaliyun /> : <Navigate to="/default" />} />
          <Route path='/uploadfile' element={isLoggedIn.current ? <UploadFile /> : <Navigate to="/default" />} />
          <Route path='/login' element={<Login isLoggedIn={isLoggedIn} setIsLoggedInS={setIsLoggedInS} />} />
          <Route path='/usersetting' element={isLoggedIn.current ? <UserSetting /> : <Navigate to="/default" />} />

          <Route path='/myorder' element={<MyOrder />} />
          <Route path='/testlatex' element={isLoggedIn.current ? <Testlatex /> : <Navigate to="/default" />} />
          <Route path='/admin' element={isLoggedIn.current ? <AdminManage /> : <Navigate to="/default" />} />
          <Route path='/familyhead' element={isLoggedIn.current ? <FamilyHead /> : <Navigate to="/default" />} />
          <Route path='/student' element={isLoggedIn.current ? <Student /> : <Navigate to="/default" />} />
          <Route path='/testgeo' element={isLoggedIn.current ? <TestGeo /> : <Navigate to="/default" />} />
          <Route path='/proxy' element={isLoggedIn.current ? <ProxyManage /> : <Navigate to="/default" />} />
          <Route path='/science' element={isLoggedIn.current ? <ScienceC /> : <Navigate to="/default" />} />
          <Route path='/myoi' element={isLoggedIn.current ? <MyOiModule /> : <Navigate to="/default" />} />
          
          <Route path='/oicourseware' element={isLoggedIn.current ? <OiCoursewareComponent /> : <Navigate to="/default" />} />
          <Route path='/publicdoc' element={isLoggedIn.current ? <StudyDocumentComponent /> : <Navigate to="/default" />} />
          <Route path='/teacher' element={isLoggedIn.current ? <ProblemTeacher /> : <Navigate to="/default" />} />
          <Route path='/algorithm' element={isLoggedIn.current ? <Algorithm /> : <Navigate to="/default" />} />
          <Route path='/algorithmbook' element={isLoggedIn.current ? <AlgorithmBook /> : <Navigate to="/default" />} />
          <Route path='/bigcleverness' element={isLoggedIn.current ? <BigCleverness /> : <Navigate to="/default" />} />
          <Route path='/interactivecourseware' element={isLoggedIn.current ? <InteractiveCourseware /> : <Navigate to="/default" />} />
          
        </Routes>
      </Layout>
    </Router>

  )
}
export default App